/* eslint-disable flowtype/require-valid-file-annotation */

const template = /* html */ `
  <div>
    <slot name="trigger"></slot>
    <dialog>
      <button class="corner-close-btn" class="close-btn">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="corner-close-icon">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
      </button>
    <header class="title-wrapper">
      <div class="title-inner-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="title-icon danger">
          <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="title-icon warning">
          <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clip-rule="evenodd" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="title-icon success">
          <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
        </svg>
        <h2 id="title"></h2>
      </div>
    </header>
    <div class="inner-wrapper">
      <slot name="content"></slot>
      <footer>
        <slot name="close-btn" class="close-btn"></slot>
        <div class="action-btns">
          <slot name="secondary-btn"></slot>
          <slot name="primary-btn"></slot>
        <div>
      </footer>
    </div>
    </dialog>

    <style>
      .inner-wrapper {
        padding: 24px;
        padding-top: 0;
      }
      .title-inner-wrapper {
        padding: 16px 24px;
        display: flex;
        gap: 8px;
        align-items: center;
      }
      dialog {
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;
        pointer-events: none;
        padding: 0;
        border: 0;
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
        width: 100%;
        opacity: 0;
        max-width: 100vw;
        box-shadow: 0 0 0 100vmax rgba(43, 63, 87, 0.4);
      }
      dialog.dark {
        background-color: #182c39;
      }
      @media (min-width: 768px) {
        dialog {
          border-radius: 12px;
          width: 560px;
          margin: auto;
        }
      }
      dialog[open]{
        animation: fadeInUp 0.25s ease-out;
        pointer-events: revert;
        opacity: 1;
      }

      @media (prefers-reduced-motion: reduce) {
        dialog[open]{
          animation: none;
        }
      }
      dialog[open]::backdrop{
        backdrop-filter: blur(4px);
      }
      @keyframes fadeInUp {
          from {
              opacity: 0;
              transform: translate3d(0, 100%, 0);
          }
          to {
              opacity: 1;
              transform: translate3d(0, 0, 0);
          }
      }
      .title-wrapper {
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
      }
      .title-icon {
        display: none;
      }
      .corner-close-icon {
        display: block;
        width: 22px;
        height: 22px;
        pointer-events: none;
      }
      .title-wrapper.danger .title-icon.danger, .title-wrapper.warning .title-icon.warning, .title-wrapper.success .title-icon.success {
        display: block;
        width: 20px;
        height: 20px;
      }
      .title-wrapper.danger #title, .title-wrapper.confirm #title, .title-wrapper.success #title {
        color: #ffffff;
      }
      .title-icon.warning, .title-wrapper.warning #title {
        color: rgb(230, 81, 0);
      }
      .title-icon.success, .title-wrapper.success #title {
        color: rgb(6, 95, 70);
      }
      .title-wrapper.danger, .title-wrapper.confirm, .title-wrapper.warning, .title-wrapper.success {
        color: white;
        margin-bottom: 24px;
      }
      .title-wrapper.default {
        background-color: rgb(240, 242, 245);
        margin-bottom: 24px;
      }
      .title-wrapper.danger {
        background: linear-gradient(to right bottom, rgb(225, 20, 20), rgb(194, 20, 20));
      }
      .title-wrapper.confirm {
        background: linear-gradient(to right bottom, rgb(28, 179, 217), rgb(25, 127, 166));
      }
      .title-wrapper.warning {
       background-color: rgb(255, 230, 179);
      }
      .title-wrapper.success {
        background-color: rgb(177, 252, 217);
      }
      #title {
        margin: 0;
        font-size: 18px;
        color: #3c4754;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
      }
      .corner-close-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #708093;
        background-color: #ffffff;
        position: absolute;
        top:4px;
        right:4px;
        height: 25px;
        width: 25px;
        padding: 0px;
        border: none;
        border-radius: 100px;
        box-shadow: -8px 9px 25px -5px rgba(0, 0, 0, 0.17), -6px -1px 10px -5px rgba(0, 0, 0, 0.04);
        cursor: pointer;
        z-index: 99;
      }
      .corner-close-btn:hover {
        background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
        --tw-gradient-from: #F0F2F5 var(--tw-gradient-from-position);
        --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
        --tw-gradient-to: #CAD2DE var(--tw-gradient-to-position);
      }
      dialog.dark .title-wrapper.default #title {
        color: #ffffff;
      }
      dialog.dark .title-wrapper.default .title-inner-wrapper {
        background-color: #193448;
      }
      footer {
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
        padding-top: 16px;
        border-top: 1px solid rgba(202, 210, 222, 0.4) !important;
      }
      .action-btns {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        gap: 4px;
      }
    </style>
  </div>
`

export default class Modal extends HTMLElement {
  static observedAttributes = ["backdropclose"]
  constructor() {
    super()
    this.attachShadow({ mode: "open" })
    this.shadowRoot.innerHTML = template
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === "backdropclose" && newValue === "false") {
      this.setupPreventCloseListener()
    } else if (name === "backdropclose" && newValue === "true") {
      this.removePreventCloseListener()
    }
  }

  get type() {
    return this.getAttribute("type") || "default"
  }

  get heading() {
    return this.getAttribute("heading")
  }

  // Default is `true`. If nothing, or anything except `false`, is passed, will close on click outside modal. If `false` is passed, will not.
  get backdropClose() {
    return this.getAttribute("backdropClose") !== "false"
  }

  // Default is `true`. If nothing, or anything except `false`, is passed, will size reasonably. If `false` is passed, will stretch.
  get fixedWidth() {
    return this.getAttribute("fixedWidth") !== "false"
  }

  // Default is `false`. If `true` is passed, will hide footer. Otherwise, will not.
  get hideFooter() {
    return this.getAttribute("hideFooter") === "true"
  }

  get skeletonMode() {
    return this.getAttribute("skeletonMode") === "true"
  }

  // Default is `false`. If `true` is passed, will default to open. Otherwise, will not.
  get defaultToOpen() {
    return this.getAttribute("defaultToOpen") === "true"
  }

  // Default is 'false'. If `true` is passed, a small close button will render in the top right of the modal.
  get cornerClose() {
    return this.getAttribute("cornerClose") === "true"
  }

  get preventAutoReset() {
    return this.getAttribute("preventAutoReset") === "true"
  }

  get isOpen() {
    return this.shadowRoot.querySelector("dialog").open
  }

  get id() {
    return this.getAttribute("id") || false
  }

  openModal() {
    this.setDarkModeStyles()
    document.body.classList.add("overflow-hidden")
    this.shadowRoot.querySelector("dialog").showModal()

    if (!this.preventAutoReset) {
      // Every time the modal gets opened, reload its contents based on the original HTML provided to `slot[name=content]`.
      // The first time it is loaded we don't need to do this (since they won't have been changed yet).
      const slot = this.shadowRoot.querySelector("slot[name=content]").assignedNodes()[0]
      if (slot) {
        if (!this.originalContent) {
          this.originalContent = slot.innerHTML
        } else {
          slot.innerHTML = this.originalContent
        }
      }
    }

    if (this.id) {
      this.dispatchEvent(new CustomEvent(`${this.id}-modalOpened`))
    }
  }

  connectedCallback() {
    const $ = (selector) => this.shadowRoot.querySelector(selector)

    if (this.skeletonMode) {
      $(".title-wrapper").style.display = "none"
      $(".inner-wrapper").style.padding = "0"
      $("footer").style.display = "none"
    }

    if (this.hideFooter) {
      $("footer").style.display = "none"
    }

    if (this.heading) {
      $("#title").innerText = this.heading
      $(".title-wrapper").classList.add(this.type)
    } else {
      $("#title").style.display = "none"
    }

    if (!this.backdropClose) {
      this.setupPreventCloseListener()
    }

    if (!this.fixedWidth) {
      $("dialog").style.width = "max-content"
    }

    if (!this.cornerClose) {
      $(".corner-close-btn").style.display = "none"
    }

    if (this.defaultToOpen) {
      this.openModal()
    }

    $("slot[name='trigger']").addEventListener("click", () => {
      this.openModal()
    })

    this.shadowRoot.addEventListener("click", (e) => {
      // Prevents the dropdown-menu-controller from closing on an outside click.
      // Necessary if you have a dots menu that launches a WF modal.
      e.dropdownCloseDisabled = true

      if (e.target.getAttribute("slot") === "close-btn" || e.target.classList.contains("close-btn")) {
        this.close()
      }
      if (this.backdropClose && e.target === $("dialog")) {
        this.close()
      }

      if (!this.backdropClose && e.target === $("dialog")) {
        document.dispatchEvent(new CustomEvent(`${this.id}-modalClosePrevented`))
      }
    })

    document.addEventListener("keydown", this.closeOnEscKey)
    document.addEventListener("turbo:morph", this.close)
  }

  closeOnEscKey = (event) => {
    if (event.key === "Escape") {
      this.close()
    }
  }

  disconnectedCallback() {
    document.removeEventListener("turbo:morph", this.close)
    document.removeEventListener("keydown", this.closeOnEscKey)
    delete this.originalModal
  }

  preventCloseListener(context) {
    return function (e) {
      e.preventDefault()
      document.dispatchEvent(new CustomEvent(`${context.id}-modalClosePrevented`))
    }
  }

  setupPreventCloseListener() {
    this.shadowRoot.querySelector("dialog").addEventListener("cancel", this.preventCloseListener(this))
  }

  removePreventCloseListener() {
    this.shadowRoot.querySelector("dialog").removeEventListener("cancel", this.preventCloseListener(this))
  }

  setDarkModeStyles() {
    const darkModeRoutes = ["mobile_app"]
    const firstRouteParam = window.location.pathname.split("/")[1]
    if (!darkModeRoutes.includes(firstRouteParam)) {
      return
    }
    const darkModeActive = window.localStorage.getItem("DARK_MODE") === "1"
    if (darkModeActive) {
      this.shadowRoot.querySelector("dialog").classList.add("dark")
    } else {
      this.shadowRoot.querySelector("dialog").classList.remove("dark")
    }
  }

  close = () => {
    if (this.isOpen) {
      if (this.id) {
        document.dispatchEvent(new CustomEvent(`${this.id}-modalClosed`))
      }
      this.shadowRoot.querySelector("dialog").close()
      document.body.classList.remove("overflow-hidden")
    }
  }
}

/* eslint-enable flowtype/require-valid-file-annotation */
